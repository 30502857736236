<template>
    <OApryseEditor 
        readOnly tagRecognizer
        :annotsDataObject="props.annotsDataObject"
        :pdfSrc="{ viewName: 'aviw_Arena_DocumentsDetail', primKey: `${props.primKey}`, fileName: '1.pdf' }"
        ref="apryseRef"
        @EditorMounted="editorMounted"
    >
        <template #header-top-left>
            <i class="bi bi-magic" @click="searchForTags" v-tooltip="{title: 'Search for tags', placement: 'bottom', trigger: 'hover', delay: { show: 600, hide: 100 }}"></i>
        </template>
    </OApryseEditor>
    <OObjectsLookup ref="objLookupRef" :bind="objectSelected" />   
</template>

<script setup lang="ts">
    import type { DataObject } from 'o365-dataobject';
    import { ref } from 'vue';
    import { OApryseEditor } from 'o365-apryse';
    import { OObjectsLookup } from 'o365-system-lookups';
    import { vTooltip } from 'o365-vue-utils';
    export interface Props {
        primKey: string
        annotsDataObject: DataObject
        objectsDataObject: DataObject
    };
    
    const props = defineProps<Props>();
    const apryseRef = ref();
    const objLookupRef = ref();
    var instance: WebViewerInstance = undefined;
    var lastSelectedAnnotation = undefined;

    function editorMounted(viewer: WebViewerInstance){
        instance = viewer;
        instance.Core.annotationManager.disableReadOnlyMode();
        instance.Core.annotationManager.promoteUserToAdmin();
        instance.UI.disableElements(['annotationStyleEditButton','linkButton','annotationDeleteButton']);
        instance.UI.setAnnotationContentOverlayHandler((annotation: Core.Annotations.Annotation) => { 
            if(annotation.getCustomData("Object")){
                let json = JSON.parse(annotation.getCustomData("Object"))
                let object = props.objectsDataObject.data.find(row => row.ID == json.ObjectRequirement_ID)
                if(object){
                    const div = document.createElement('div'); 
                    div.innerHTML = `
                    <div style="display: flex; align-items: flex-start;">
                        <div style="flex: 1">
                            <p><b>Object Type:</b> ${object.ObjectType}</p>
                            <p><b>Name / Tag.No:</b> ${object.Object}</p>
                            <p><b>Description:</b> ${object.ObjectDescription ?? ''}</p>
                            <p><b>Org Unit:</b> ${object.OrgUnit}</p>
                        </div>
                    </div>
                    `
                    return div;
                }
            }
            else{
                const div = document.createElement('div')
                div.appendChild(document.createTextNode(`Created by: ${annotation.Author}`)); 
                return div;
            } 
        });


        const customBtn = {
            type: 'actionButton',
            img: "ic-delete",
            dataElement: 'deleteTag',
            title: "Delete",
            onClick: () => {instance.Core.annotationManager.deleteAnnotation(lastSelectedAnnotation, { force: true });},
        };
        const customBtn2 = {
            type: 'actionButton',
            img: "icon-menu-add",
            dataElement: 'addTag',
            title: "Add Object/Tag",
            onClick: () => objLookupRef.value.showModal(),
        };
        instance.UI.annotationPopup.add(customBtn);
        instance.UI.annotationPopup.add(customBtn2);
        instance.Core.annotationManager.addEventListener('annotationSelected', (annots) => {
            if (annots && annots[0]) {
                switch(true) {
                case annots[0] instanceof instance.Core.Annotations.RectangleAnnotation:
                    lastSelectedAnnotation = annots[0];
                    instance.UI.enableElements([customBtn.dataElement]);
                    !isReferencingObject(annots[0]) ? instance.UI.enableElements([customBtn2.dataElement]) : instance.UI.disableElements([customBtn2.dataElement]);
                    break;
                default:
                    instance.UI.disableElements([customBtn.dataElement,customBtn2.dataElement]);
                    break;
                }
            }
        });

        instance.Core.Annotations.SelectionModel.setCustomHandlers(instance.Core.Annotations.BoxSelectionModel, {
            testSelection: (annotation, x, y, pageMatrix, zoom, rotation, { selectionModel, originalTestSelection }) => {
                if (annotation instanceof instance.Core.Annotations.RectangleAnnotation) {
                    return instance.Core.Annotations.SelectionAlgorithm.boundingRectTest(annotation, x, y, zoom);
                }
            }
        });

        instance.Core.documentViewer.getTool('AnnotationCreateRectangle').setStyles({
            StrokeColor: new instance.Core.Annotations.Color(0, 135, 238)
        })
    }

    async function searchForTags(){
        let tags = props.objectsDataObject.data.map(row => row.Object).join('|');
        const { documentViewer, annotationManager, Search  } = instance.Core;
        documentViewer.textSearchInit(tags, Search.Mode.REGEX | Search.Mode.HIGHLIGHT , {
            fullSearch: true,
            onResult: (result) => {
                if (result.resultCode === Search.ResultCode.FOUND) {
                    const newAnnotation = new instance.Core.Annotations.RectangleAnnotation();
                    newAnnotation.PageNumber = result.pageNum;
                    //newAnnotation.Quads = result.quads.map(quad => quad.getPoints());
                    let rect = convertQuadsToRect(result.quads);
                    newAnnotation.setRect(new instance.Core.Math.Rect(rect.x1-5,rect.y1-5,rect.x2+5,rect.y2+5))
                    newAnnotation.StrokeColor = new instance.Core.Annotations.Color(0, 135, 238);
                    newAnnotation.Author = result.resultStr
                    newAnnotation.setCustomData("Object", JSON.stringify({ ObjectRequirement_ID: props.objectsDataObject.data.find(row => row.Object == result.resultStr)?.ID }) )
                    annotationManager.addAnnotation(newAnnotation);
                    annotationManager.redrawAnnotation(newAnnotation);
                }
            },
        })
    }

    function onClicked(){
        console.log(lastSelectedAnnotation.getRect())
    }

    function convertQuadsToRect(quads) {
        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        quads.forEach(q => {
            let quad = q.getPoints();
            minX = Math.min(minX,quad.x1,quad.x2,quad.x3,quad.x4);
            maxX = Math.max(maxX,quad.x1,quad.x2,quad.x3,quad.x4);
            minY = Math.min(minY,quad.y1,quad.y2,quad.y3,quad.y4);
            maxY = Math.max(maxY,quad.y1,quad.y2,quad.y3,quad.y4);
        });

        return {
            x2: maxX,
            x1: minX,
            y2: maxY,
            y1: minY
        }
    }

    function isReferencingObject(annotation){
        let json = annotation.getCustomData("Object");
        if(!json) { return false }
        let object = JSON.parse(json)
        return !!props.objectsDataObject.data.find(row => row.ID == object.ObjectRequirement_ID);
    }

    async function objectSelected(row){
        let obj = props.objectsDataObject.data.find(obj => obj.Object == row.Name)?.ID;
        if(!obj){
            await props.objectsDataObject.createNew({ Object_ID: row._item.ID})
            await props.objectsDataObject.load();
            obj = props.objectsDataObject.data.find(obj => obj.Object == row.Name)?.ID;
        }
        lastSelectedAnnotation.setCustomData("Object", JSON.stringify({ ObjectRequirement_ID: obj }))
        lastSelectedAnnotation.Author = row._item.Name;
        instance.Core.annotationManager.trigger('annotationChanged', [[lastSelectedAnnotation], 'modify', {}]);
        instance.Core.annotationManager.updateAnnotation(lastSelectedAnnotation);
        instance.Core.annotationManager.redrawAnnotation(lastSelectedAnnotation);
    }
</script>
